import { ROUTE } from '~/constants/routes'
import AuthenticationService, {
  AUTHENTICATION_STATUS
} from '~/services/AuthenticationService'
import { useCurrentUserStore } from '~/stores/currentUser'
import { QUERY_PARAMETERS } from '~/types/queryParameters'

export default defineNuxtRouteMiddleware(async () => {
  const { isLoggedIn, status } = AuthenticationService.isLoggedIn()

  const { resetCurrentUser } = useCurrentUserStore()

  if (!isLoggedIn) {
    if (status === AUTHENTICATION_STATUS.EMAIL_NOT_VERIFIED) {
      resetCurrentUser()
      return navigateTo(ROUTE.LOGIN)
    }

    if (status === AUTHENTICATION_STATUS.TOKEN_EXPIRED) {
      try {
        await AuthenticationService.refreshAccessTokenUsingRefreshToken()
      } catch (err) {
        resetCurrentUser()

        const route = useRoute()

        return navigateTo({
          path: ROUTE.LOGIN,
          query:
            route.fullPath === ROUTE.ROOT
              ? undefined
              : {
                  [QUERY_PARAMETERS.REDIRECT_URL]: encodeURIComponent(
                    route.fullPath
                  )
                }
        })
      }
    }
  }
})
